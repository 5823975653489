/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Badge, Box, Button, Icon, Select, SimpleGrid, Spinner, Stack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useContent } from "hooks/useContent";
import { AuthContext } from "contexts/AuthContext";
import { useBroadcast } from "hooks/useBroadcast";
import { MdClose } from "react-icons/md";
import { ActionDrawer } from "components/drawer";
import BroadcastModal from "views/modal/broadcast";
import { ConfirmationDialog } from "components/confirmation/confirmation";
import { remove } from "lib/firebase";
import DataTableNew from "components/table/DataTableNew";
import { columnsBroadcasts } from "./columns/broadcasts";
import { tokenFilter } from "lib/utils";

export default function BroadcastsPage() {
  const { user } = useContext(AuthContext)
  const [broadcasts, loading, error, refresh] = useBroadcast({ user })

  const [filter, setFilter] = useState({
    hasChyron: undefined
  })

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <DataTableNew
        columns={columnsBroadcasts}
        data={broadcasts.filter(br => {
          if (br.broadcastList) return false
          if (!filter.hasChyron) return true
          return br.showChyron === (filter.hasChyron === 'yes')
        })}
        loading={loading}
        refresh={refresh}
        search={(item, query) => tokenFilter(item.id + item.name, query)}
        filters={(
          <Select placeholder=''
            style={{borderRadius: '30px' }}
            width={170}
            value={filter.hasChyron}
            onChange={e => setFilter({ hasChyron: e.target.value})}>
            <option key='yes' value='yes'>With chyron</option>
            <option key='no' value='no'>No chyron</option>
          </Select>
        )}
        actions={(
          <ActionDrawer
            title='Create broadcast'
            size={'xl'}
            trigger={
              <Button colorScheme='brand' variant='solid' size='md' borderRadius={30} px='8'>
                New broadcast
              </Button>
            }
            >
            <BroadcastModal onChange={refresh} />
          </ActionDrawer>
        )}
      />
    </Box>
  );
}
